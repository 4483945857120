import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show or hide the button based on scroll position
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener to window
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <button
        onClick={() => scrollToTop()}
        className={`${
          isVisible ? 'block' : 'hidden'
        } bg-[#0E2D9D] px-[10px] fixed bottom-4 right-5 h-10 w-10 z-[45] text-white rounded-full duration-300 ease-in-out hover:opacity-70 cursor-pointer transition-opacity`}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='19'
          height='11'
          viewBox='0 0 19 11'
          fill='none'
        >
          <path
            d='M2.25 9.5L9.75 2L17.25 9.5'
            stroke='#fff'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </button>
    </div>
  );
};

export default ScrollToTopButton;
