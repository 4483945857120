import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const getFormByFormIdURL = process.env.GATSBY_DYNAMIC_FORM_API_URL + '/v1/organization/';

const approveFormsURL = process.env.GATSBY_DYNAMIC_FORM_API_URL + '/v1/organization/';

const initialStateObject = {
    formsByFormId: {
        loading: false,
        error: '',
        data: {},
    },
    getAllApprovedForms: {
        loading: false,
        error: '',
        data: {},
    },
};

export const getFormByFormId = createAsyncThunk(
    'dynamicForms/getFormByFormId',
    (data) => {
        return axios
            .get(
                getFormByFormIdURL +
                data.org_id +
                '/project/' +
                data.project_id +
                '/form/' +
                data.form_id
            )
            .then((response) => response.data);
    }
);

export const getAllApprovedForms = createAsyncThunk(
    'dynamicForms/getAllApprovedForms',
    (data) => {
        return axios
            .post(
                approveFormsURL +
                data.data.org_id +
                '/project/' +
                data.data.project_id +
                '/user/' +
                data.data.user_id +
                '/forms/approved',
                data.payload
            )
            .then((response) => response.data);
    }
);

const DynamicFormSlice = createSlice({
    name: 'dynamicForms',
    initialState: initialStateObject,
    extraReducers: (builder) => {
        // getFormByFormId
        builder.addCase(getFormByFormId.pending, (state) => {
            state.formsByFormId.loading = true;
        });
        builder.addCase(getFormByFormId.fulfilled, (state, action) => {
            state.formsByFormId.loading = false;
            state.formsByFormId.data = action.payload;
            state.formsByFormId.error = '';
        });
        builder.addCase(getFormByFormId.rejected, (state, action) => {
            state.formsByFormId.loading = false;
            state.formsByFormId.error = action.error.message;
        });

        // getAllApprovedForms
        builder.addCase(getAllApprovedForms.pending, (state) => {
            state.getAllApprovedForms.loading = true;
        });
        builder.addCase(getAllApprovedForms.fulfilled, (state, action) => {
            state.getAllApprovedForms.loading = false;
            state.getAllApprovedForms.data = action.payload;
            state.getAllApprovedForms.error = '';
        });
        builder.addCase(getAllApprovedForms.rejected, (state, action) => {
            state.getAllApprovedForms.loading = false;
            state.getAllApprovedForms.error = action.error.message;
        });
    },
});

export default DynamicFormSlice.reducer;