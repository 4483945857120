import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../utils/axiosInstance';

const initialStateObject = {
  getAllParentChildSubChildMenus: {
    loading: false,
    error: '',
    data: {},
  },
};

const tokenStr = process.env.GATSBY_STRAPI_ACCESS_TOKEN;

export const getAllParentChildSubChildMenu = createAsyncThunk(
  'megaMenu/getAllParentChildSubChildMenu',
  (data) => {
    return axiosInstance(data)
      .then((response) => response.data)
      .catch((err) => {
        if (err.response) {
          return err.response.data;
        }
      });
  }
);

// Utility function for removing duplicates
const removeDuplicates = (array, key) => {
  const uniqueItems = new Map();
  array.forEach((item) => uniqueItems.set(item[key], item));
  return Array.from(uniqueItems.values());
};

const MegaMenuSlice = createSlice({
  name: 'megaMenu',
  initialState: initialStateObject,
  reducers: {
    resetState: (state) => {
      state.getAllParentChildSubChildMenus.data = []; // Reset specific state properties
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllParentChildSubChildMenu.pending, (state) => {
      state.getAllParentChildSubChildMenus.loading = true;
    });
    builder.addCase(
      getAllParentChildSubChildMenu.fulfilled,
      (state, action) => {
        state.getAllParentChildSubChildMenus.loading = false;
        const combinedData = [
          ...state.getAllParentChildSubChildMenus.data,
          ...action.payload,
        ];
        state.getAllParentChildSubChildMenus.data = removeDuplicates(
          combinedData,
          'id'
        );
        state.getAllParentChildSubChildMenus.error = '';
      }
    );
    builder.addCase(getAllParentChildSubChildMenu.rejected, (state, action) => {
      state.getAllParentChildSubChildMenus.loading = false;
      state.getAllParentChildSubChildMenus.error = action.error.message;
    });
  },
});

export default MegaMenuSlice.reducer;
export const { resetState } = MegaMenuSlice.actions;
